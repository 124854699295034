/* font-size */
.fs-10 {
    font-size: var(--font-size-10);
}

.fs-12 {
    font-size: var(--font-size-12);
}

.fs-14 {
    font-size: var(--font-size-14);
}

.fs-15 {
    font-size: var(--font-size-15);
}

.fs-16 {
    font-size: var(--font-size-16);
}

.fs-17 {
    font-size: var(--font-size-17);
}

.fs-18 {
    font-size: var(--font-size-18);
}

.fs-20 {
    font-size: var(--font-size-20);
}

.fs-22 {
    font-size: var(--font-size-22);
}

.fs-24 {
    font-size: var(--font-size-24);
}

.fs-30 {
    font-size: var(--font-size-30);
}

/* font-weight */

.font-weight-300 {
    font-weight: var(--font-weight-300);
}

.font-weight-400 {
    font-weight: var(--font-weight-400);
}

.font-weight-500 {
    font-weight: var(--font-weight-500);
}

.font-weight-600 {
    font-weight: var(--font-weight-600);
}

.font-weight-700 {
    font-weight: var(--font-weight-700);
}

/* margin */

.m-0 {
    margin: var(--no-margin);
}

.m-1 {
    margin: var(--margin-lighter);
}

.m-2 {
    margin: var(--margin-light);
}

.m-3 {
    margin: var(--margin-medium);
}

.m-4 {
    margin: var(--margin-large);
}

.m-5 {
    margin: var(--margin-largest);
}

/* padding */

.p-0 {
    padding: var(--no-padding);
}

.p-1 {
    padding: var(--padding-lighter);
}

.p-2 {
    padding: var(--padding-light);
}

.p-3 {
    padding: var(--padding-medium);
}

.p-4 {
    padding: var(--padding-large);
}

.p-5 {
    padding: var(--padding-largest);
}


/* border-radius */

.m-0 {
    border-radius: var(--no-border-radius);
}

.m-1 {
    border-radius: var(--border-radius-lighter);
}

.m-2 {
    border-radius: var(--border-radius-light);
}

.m-3 {
    border-radius: var(--border-radius-medium);
}

.m-4 {
    border-radius: var(--border-radius-large);
}

.m-5 {
    border-radius: var(--border-radius-largest);
}

/* text-color */

.text-primary {
    color: var(--ion-color-primary) !important;
}

.text-secondary {
    color: var(--ion-color-secondary);
}

.text-tertiary {
    color: var(--ion-color-tertiary);
}

.text-dark {
    color: var(--ion-color-dark);
}

.text-light {
    color: var(--ion-color-light);
}

.text-muted {
    color: var(--ion-color-muted);
}

.text-success {
    color: var(--ion-color-success);
}

.text-warning {
    color: var(--ion-color-warning);
}

.text-danger {
    color: var(--ion-color-danger);
}

/* bg */

.bg-primary {
    background: var(--ion-color-primary);
}

.bg-secondary {
    background: var(--ion-color-secondary);
}

.bg-tertiary {
    background: var(--ion-color-tertiary);
}

.bg-dark {
    background: var(--ion-color-dark);
}

.bg-light {
    background: var(--ion-color-light);
}

.bg-muted {
    background: var(--ion-color-muted);
}

.bg-success {
    background: var(--ion-color-success);
}

.bg-warning {
    background: var(--ion-color-warning);
}

.bg-danger {
    background: var(--ion-color-danger);
}

ion-accordion.accordion-expanding ion-item[slot="header"],
ion-accordion.accordion-expanded ion-item[slot="header"] {
    font-weight: 700;
}