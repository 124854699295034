/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #f66e06;
  /* --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff; */

  /** secondary **/
  --ion-color-secondary: #4f4f4f;
  /* --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff; */

  /** tertiary **/
  --ion-color-tertiary: #ffe8cc;
  /* --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff; */

  /** success **/
  --ion-color-success: #008000;
  /* --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d; */

  /** warning **/
  --ion-color-warning: #f31f3a;
  /* --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22; */

  /** danger **/
  --ion-color-danger: #cf3c4f;
  /* --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b; */

  /** dark **/
  --ion-color-dark: #000;
  /* --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e; */

  /** medium **/
  --ion-color-muted: #797f85;
  /* --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6; */

  /** light **/
  --ion-color-light: #fff;
  /* --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9; */

  /* blue */
  --ion-color-blue: #615cfe;

  /* purplle */
  --ion-color-purplle: #b95dff;

  /* pushnotification btn */
  /* --ion-color-pushbtn: #dfe3fc;
  --ion-color-pushbtn-lighter: #f0f4ff;
  --ion-color-pushbtn-darker: #c9cdf2;

  --ion-color-c4cdd5: #69bb7b;
  --ion-color-c4cdd5-rgb: 105, 187, 123;
  --ion-color-c4cdd5-contrast: #000000;
  --ion-color-c4cdd5-contrast-rgb: 0, 0, 0;
  --ion-color-c4cdd5-shade: #5ca56c;
  --ion-color-c4cdd5-tint: #78c288; */
  /* 
  /* Text colors for pushnotification btn variants */
  /* --ion-color-pushbtn-text: #393ce0; */
  /* Default text color */
  /* --ion-color-pushbtn-lighter-text: #676dc8; */
  /* Text color for lighter variant */
  /* --ion-color-pushbtn-darker-text: #000000; */

  /* font size */
  --font-size-10: 10px;
  --font-size-12: 12px;
  --font-size-14: 14px;
  --font-size-15: 15px;
  --font-size-16: 16px;
  --font-size-17: 17px;
  --font-size-18: 18px;
  --font-size-20: 20px;
  --font-size-22: 22px;
  --font-size-24: 24px;
  --font-size-30: 30px;

  /* font Weight */
  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;

  /* margin */
  --no-margin: 0;
  --margin-lighter: 0.25rem;
  --margin-lighter: 0.5rem;
  --margin-medium: 1rem;
  --margin-large: 1.5rem;
  --margin-largest: 3rem;

  /* padding */
  --no-padding: 0;
  --padding-lighter: 0.25rem;
  --padding-lighter: 0.5rem;
  --padding-medium: 1rem;
  --padding-large: 1.5rem;
  --padding-largest: 3rem;

  /* border-radius */
  --no-border-radius: 0;
  --border-radius-lighter: 5px;
  --border-radius-lighter: 10px;
  --border-radius-medium: 14px;
  --border-radius-large: 25px;
  --border-radius-largest: 40px;
  --border-radius-full: 50%;
}

ion-skeleton-text {
  --border-radius: 9999px;
}
