@media screen and (max-width: 575px) {
    .tab-bar-header {
        display: none;
    }

    .header-common-box {
        display: none;
    }

}

@media screen and (min-width: 640px) and (max-width: 768px) {
    .box-profile {
        min-width: 46.8%;
        display: inline-block;
    }
}

@media screen and (min-width: 575px) and (max-width: 768px) {
    .header-item-text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 21px;
        text-align: center;

    }

    .form-control-input {
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 0.6rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        appearance: none;
        border-radius: 0.375rem;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    .nav-item {
        padding: 0px 14px;
        display: flex;
        align-items: center;
    }

    .navbar {
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    /* .auth-web-bottom {
        position: absolute;
        width: 480px;
        height: fit-content;
        top: 254px;
        right: 0px;
        margin-right: 10rem;
        background: #ffffff;
        border-radius: 24px;
        padding-bottom: 22px;
    } */

    .languageDropdown {
        width: 100%;
    }

    ion-popover::part(content) {
        width: fit-content !important;
        left: 32px !important;
        max-height: 500px;
    }

    .header-top {
        display: none;
    }

    .FooterPanel {
        display: none;
    }

    .bottomCover {
        position: absolute;
        width: 480px;
        height: fit-content;

        top: 254px;
        right: 0px;
        margin-right: 10rem;
        background: #FFFFFF;
        border-radius: 24px;
        padding-bottom: 22px;
    }

    .favorite-body {
        margin-top: 5rem;
    }

    .home-body {
        margin-top: 0rem;
    }

    .interestBody {
        margin-top: 5rem;
    }

    .profile-body {
        margin-top: 5rem;
    }

    .search-body-part {
        margin-top: 5rem;
    }

    .action-sheet-container.sc-ion-action-sheet-md {
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        height: 100%;
        max-height: 100%;
        margin-left: 10rem;
        margin-right: 10rem;
    }

    .action-sheet-wrapper.sc-ion-action-sheet-md {
        width: 100%;
        display: initial;
        position: relative;
    }

    .alert-wrapper.sc-ion-alert-md {
        min-width: -webkit-fill-available;
        margin: 15rem;
    }

    .alert-message img {
        width: 130px;
        height: auto;
    }

    .settingBody {
        top: 15px;
    }

    .bio-data-upload-card {
        margin-top: 20px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {

    .header-item-text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 21px;
        text-align: center;

    }

    .form-control-input {
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 0.6rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        appearance: none;
        border-radius: 0.375rem;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    .nav-item {
        padding: 0px 14px;
        display: flex;
        align-items: center;
    }

    .navbar {
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .box-profile {
        min-width: 47.3%;
        display: inline-block;
    }

    /*     
    .auth-web-bottom {
        position: absolute;
        width: 480px;
        height: fit-content;
        top: 254px;
        right: 0px;
        margin-right: 10rem;
        background: #ffffff;
        border-radius: 24px;
        padding-bottom: 22px;
    } */

    .languageDropdown {
        width: 100%;
    }

    ion-popover::part(content) {
        width: fit-content !important;
        left: 32px !important;
        max-height: 500px;
    }

    .header-top {
        display: none;
    }

    .FooterPanel {
        display: none;
    }

    .bottomCover {
        position: absolute;
        width: 480px;
        height: fit-content;
        top: 254px;
        right: 0px;
        margin-right: 10rem;
        background: #FFFFFF;
        border-radius: 24px;
        padding-bottom: 22px;
    }

    .favorite-body {
        margin-top: 5rem;
    }

    .home-body {
        margin-top: 0rem;
    }

    .interestBody {
        margin-top: 5rem;
    }

    .profile-body {
        margin-top: 5rem;
    }

    .search-body-part {
        margin-top: 5rem;
    }

    .action-sheet-container.sc-ion-action-sheet-md {
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        height: 100%;
        max-height: 100%;
        margin-left: 10rem;
        margin-right: 10rem;
    }

    .action-sheet-wrapper.sc-ion-action-sheet-md {
        width: 100%;
        display: initial;
        position: relative;
    }

    .alert-wrapper.sc-ion-alert-md {
        min-width: -webkit-fill-available;
        margin: 15rem;
    }

    .alert-message img {
        width: 130px;
        height: auto;
    }

    .settingBody {
        top: 15px;
    }

    .bio-data-upload-card {
        margin-top: 20px;
    }

}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
    .swiper-slide {
        max-height: 26rem;
        margin-top: -6px;
        margin-bottom: -11px;
        border: none;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .swiper-slide img {
        min-width: -webkit-fill-available;
    }

    .header-item-text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 21px;
        text-align: center;

    }

    .form-control-input {
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 0.6rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        appearance: none;
        border-radius: 0.375rem;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    .nav-item {
        padding: 0px 14px;
        display: flex;
        align-items: center;
    }

    .navbar {
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .footer {
        background-color: var(--ion-color-light);
        bottom: 0px;
        width: 100%;
        left: 0px;
        padding: 5px 15px 10px;
        border-radius: 30px 30px 0px 0px;
        -webkit-box-shadow: 1px -2px 5px 2px rgba(222, 222, 222, 1);
        -moz-box-shadow: 1px -2px 5px 2px rgba(222, 222, 222, 1);
        box-shadow: 1px -2px 5px 2px rgb(41, 4, 4);
    }

    /* .auth-web-bottom {
        position: absolute;
        width: 480px;
        height: fit-content;
        top: 254px;
        right: 0px;
        background: #FFFFFF;
        border-radius: 24px;
        margin-right: 5rem;
        padding-bottom: 26px;
    } */

    .languageDropdown {
        width: 100%;
    }

    ion-popover::part(content) {
        width: fit-content !important;
        left: 32px !important;
        max-height: 500px;
    }

    .header-top {
        display: none;
    }

    .FooterPanel {
        display: none;
    }

    .favorite-body {
        margin-top: 5rem;
    }

    .home-body {
        margin-top: 0rem;
    }

    .interestBody {
        margin-top: 5rem;
    }

    .profile-body {
        margin-top: 5rem;
    }

    .search-body-part {
        margin-top: 5rem;
    }

    .box-profile {
        min-width: 31.3%;
        display: inline-block;
    }

    .action-sheet-container.sc-ion-action-sheet-md {
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        height: 100%;
        max-height: 100%;
        margin-left: 15rem;
        margin-right: 15rem;
    }

    .action-sheet-wrapper.sc-ion-action-sheet-md {
        width: 100%;
        display: initial;
        position: relative;
    }

    /* .bottomCover {
        position: absolute;
        width: 480px;
        height: fit-content;
        top: 254px;
        right: 0px;
        margin-right: 10rem;
        background: #FFFFFF;
        border-radius: 24px;
        padding-bottom: 22px;
    } */

    .alert-wrapper.sc-ion-alert-md {
        min-width: -webkit-fill-available;
        margin: 23rem;
    }

    .alert-message img {
        width: 130px;
        height: auto;
    }

    .settingBody {
        top: 15px;
    }

    .bio-data-upload-card {
        margin-top: 20px;
    }

    .favourite-body-box {
        margin: 0px 0px 0px 0.4em;
    }
}

@media screen and (min-width: 1440px) and (max-width: 2560px) {
    .header-item-text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 21px;
        text-align: center;

    }

    .form-control-input {
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 0.6rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        appearance: none;
        border-radius: 0.375rem;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    .nav-item {
        padding: 0px 14px;
        display: flex;
        align-items: center;
    }

    .navbar {
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .settingBody {
        top: 15px;
    }

    .bio-data-upload-card {
        margin-top: 20px;
    }

    .FooterPanel {
        display: none;
    }

    /* .auth-web-bottom {
        position: absolute;
        width: 480px;
        height: fit-content;
        top: 254px;
        right: 0px;
        margin-right: 10rem;
        background: #FFFFFF;
        border-radius: 24px;
        padding-bottom: 22px;
    } */

    .languageDropdown {
        width: 100%;
    }

    ion-popover::part(content) {
        width: fit-content !important;
        left: 32px !important;
        max-height: 500px;
    }

    .header-top {
        display: none;
    }

    #menu {
        color: rgb(63, 63, 63);
        border: 1px solid rgb(78, 77, 77);
        width: 150px;
        height: 100px;
        padding: 10px;
        border-radius: 3px;
        line-height: 100px;
        margin: auto;
        display: none;
    }

    /* .bottomCover {
        position: absolute;
        width: 480px;
        height: fit-content;
        top: 254px;
        right: 0px;
        margin-right: 10rem;
        background: #FFFFFF;
        border-radius: 24px;
        padding-bottom: 22px;

    } */

    .favorite-body {
        margin-top: 5rem;
    }

    .home-body {
        margin-top: 0rem;
    }


    .interestBody {
        margin-top: 5rem;
    }

    .profile-body {
        margin-top: 5rem;
    }

    .search-body-part {
        margin-top: 5rem;
    }

    .box-profile {
        min-width: 31.9%;
        display: inline-block;
    }

    .profile-code-body {
        margin: 0px 0px 0px 0.4em;
    }

    .favourite-body-box {
        margin: 0px 0px 0px 0.4em;
    }

    .action-sheet-container.sc-ion-action-sheet-md {
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        height: 100%;
        max-height: 100%;
        margin-left: 25rem;
        margin-right: 25rem;
    }

    .action-sheet-wrapper.sc-ion-action-sheet-md {
        width: 100%;
        display: initial;
        position: relative;
    }

    .alert-wrapper.sc-ion-alert-md {
        min-width: -webkit-fill-available;
        margin: 37rem;
    }

    .alert-message img {
        width: 130px;
        height: auto;
    }

    .GoliveBody {
        border-radius: 1rem;
    }

    .swiper-slide {
        max-height: 26rem;
        margin-top: 55px;
        border-radius: none;
        border: none;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .swiper-slide img {
        min-width: -webkit-fill-available;
    }

    .profile-image-top {
        object-fit: cover;
        width: 20%;
        justify-content: center;
        display: flex;
    }

    .fourth-form-box {
        /* display: contents; */
    }

    .fifth-form-box {
        /* display: contents; */
    }

    /* save button ka classes hai */
    /* .btnFill{
            width: 10px;
        } */
}

@media screen and (min-width: 1758px) and (max-width: 2560px) {
    .box-profile {
        min-width: 32.1%;
        display: inline-block;
    }

    .profile-code-body {
        margin: 0px 0px 0px 0.2em;
    }

    .favourite-body-box {
        margin: 0px 0px 0px 0.2em;
    }
}


/* new surbhi styles */

.test-background-image {
    height: 100%;
    width: 100%;
    overflow: hidden;
    background: linear-gradient(rgb(12 12 12 / 90%), rgb(0 0 0 / 90%)) center center / cover no-repeat, url("../assets/images/background-image.png");
    background-repeat: no-repeat;
    background-position: center;
    margin: 0px;
    padding: 0px;
    position: relative;
}

.test-background-image .test-content {
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
    left: 50%;
    top: 50%;
    text-align: center;
}

.test-background-image .test-body-text h1,
.test-background-image .web-body-text h1 {
    font-size: 25px;
    line-height: 49px;
    text-align: center;
    color: #FFFFFF;
}

.test-background-image .test-body-text p,
.test-background-image .web-body-text p {
    font-size: 16px;
    line-height: 49px;
    text-align: center;
    color: #FFFFFF;
}

.test-background-image .login-button-first-page {
    --box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.13);
    --border-radius: 6px;
    --padding-top: 23px;
    --padding-bottom: 23px;
    width: 10rem;
    font-weight: 600;
    font-size: 16px;
    margin-right: 10px;
    margin-top: 10px;
}

.test-background-image .test-btn ion-button::part(native) {
    color: #fff !important;
}

.hr-text-home {
    font-size: 24px;
    width: 50px;
    margin: auto;
    background: var(--ion-color-light);
    margin-top: -34px;
    text-align: center;
    color: #000;
    font-weight: 600;
    border-radius: 50%;
    height: 50px;
    padding: 5px 10px;
}

.alert-button,
.alert-title {
    font-weight: 700 !important;
}

/* medai query */
@media screen and (min-width: 576px) and (max-width: 3000px) {


    .authbottomCover {
        border-radius: 24px;
        padding-bottom: 22px;
        height: fit-content;
        background: #ffffff;
        margin: 20px 0;
        min-width: 520px;
    }

    .alert-wrapper {
        min-width: 460px !important;
        border-radius: 20px !important;
        padding: 10px !important;
    }

}

@media screen and (min-width: 992px) and (max-width: 3000px) {
    .web-body-text {
        display: block;
    }

    .web-logo {
        margin: 0 0 20px 0;
        height: 110px;
        display: block;
    }

    .auth-content {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        height: 100vh;
        align-items: center;
        overflow: auto;
    }
}

@media screen and (min-width: 576px) and (max-width: 992px) {
    .web-logo {
        margin: 0 0 20px 0;
        height: 80px;
        display: block;
    }

    .auth-content {
        display: grid;
        justify-content: space-around;
        flex-wrap: wrap;
        height: 100vh;
        align-items: center;
        overflow: auto;
    }
}

@media screen and (min-width: 320px) and (max-width: 992px) {
    .web-body-text {
        display: none;
    }




}

@media screen and (min-width: 768px) and (max-width: 2000px) {
    .detailPageModal {
        --width: 600px;
        --height: 638px;
    }
}